<template>
  <div class="market-analysis">
    <head-nav></head-nav>
    <div class="market-analysis-banner">
      <img
        v-for="(item, index) in analysisBanner"
        :key="index"
        :src="item.imgs"
        alt=""
      >
    </div>

    <div class="market-analysis-wrap">
      <div class="market-analysis-list">
        <h3 class="market-analysis-list-title">
          <img
            :src="$t('market-analysis.title-1')"
            :class="this.$i18n.locale=='en'?'market-analysis-list-title-img':''"
            alt=""
            :style="this.$i18n.locale==='en'?'width:300px':''"
          >
        </h3>

        <div class="market-analysis-list-imgs">
          <div
            class="market-analysis-list-img"
            v-for="(item, index) in analysisList"
            :key="index"
          >
            <h3 class="market-analysis-list-img-info">{{item.title}}</h3>
            <div class="market-analysis-list-img-content">
              {{item.content}}
            </div>
          </div>

          <div class="">

          </div>
        </div>
      </div>

      <div class="market-analysis-list">
        <h3 class="market-analysis-list-title">
          <img
            :src="$t('market-analysis.title-2')"
            :class="this.$i18n.locale=='en'?'market-analysis-list-title-img':''"
            alt=""
            :style="this.$i18n.locale==='en'?'width:300px':''"
          >
        </h3>

        <div class="market-analysis-list-imgs">
          <div class="market-analysis-list-img">
            <img
              :src="analysisImgs[0].image.publicUrl"
              alt=""
            >
          </div>

          <div class="">

          </div>
        </div>
      </div>

    </div>
    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import banner from '@/assets/image/market-analysis/banner.png';

import HeadNav from './head-nav';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    Footers,
  },
  apollo: {
    analysisList: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              analysisList: allMarketAnalises {
                title
                content
              }
            }
          `;
        }
        return gql`
          query {
            analysisList: allEnMarketAnalises {
              title
              content
            }
          }
        `;
      },
    },
    analysisImgs: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              analysisImgs: allGlobalMarkets {
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query {
            analysisImgs: allEnGlobalMarkets {
              image {
                publicUrl
              }
            }
          }
        `;
      },
    },
  },
  data() {
    return {
      analysisBanner: [
        {
          id: 1,
          imgs: banner,
        },
      ],
      analysisImgs: [
        {
          image: '',
        },
      ],
      analysisList: [],
    };
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.market-analysis {
  &-banner {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .market-analysis-wrap {
    padding: 40px 30px 100px 30px;
    background: #f8f8f8;

    .market-analysis-list {
      &:last-child {
        .market-analysis-list-img {
          background: transparent;
          width: 100%;
          height: 100%;
          padding: 0 !important;

          img {
            width: 100%;
          }
        }
      }

      &-title {
        margin: 50px 0 30px 0;
        text-align: center;

        &-img {
          width: 340px;
          height: 68px;
        }

        img {
          height: 70px;
        }
      }

      &-content {
        line-height: 40px;
        font-size: 28px;
        color: #646464;
      }

      &-imgs {
        margin-top: 30px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;

        .market-analysis-list-img {
          background: #fff;
          padding: 34px 20px;

          &:first-child {
            border-bottom: 1px solid #eee;
          }

          &-info {
            text-align: center;
            width: 100%;
            font-size: 36px;
            font-weight: 500;
            color: #333333;
            color: #333;
            margin-bottom: 30px;
          }

          &-content {
            font-size: 28px;
            line-height: 36px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
}
</style>
